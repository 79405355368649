import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SpeakingPage = ({ data }) => {
  const { Title, Slug } = data.strapiPages
  const talks = data.allStrapiTalks.nodes
  return (
    <Layout>
      <SEO title={Title} />
      <section
        className="resume-section speaking-section p-3 p-xl-5 d-flex align-items-top"
        id={Slug}
      >
        <div className="w-100">
          <h2 className="mb-5 h1">{Title}</h2>

          {/* <!-- Project One Row --> */}
          <div className="row justify-content-center no-gutters mb-5 mb-xl-0">
            <div className="col-xl-6">
              <img
                className="img-fluid"
                src="https://res.cloudinary.com/leocaseiro/image/upload/v1581237480/gtdrhzlagi0uov3141le.jpg"
                alt="Leo speaking in a Meetup"
              />
            </div>
            <div className="col-xl-6">
              <div className="bg-dark text-center h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 my-auto text-center text-xl-left">
                    <h4 className="text-white">Talks (Meetups)</h4>
                    <p className="mb-0 text-white-50">
                      I often present at meetups about Software Development
                    </p>
                    <hr className="d-none d-xl-block mb-0 ml-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Project Two Row --> */}
          <div className="row justify-content-center no-gutters mb-5">
            <div className="col-xl-6">
              <img
                className="img-fluid"
                src="https://res.cloudinary.com/leocaseiro/image/upload/v1581237564/b8nb5ygyk0zgimiq91vw.jpg"
                alt="Leo running a workshop"
              />
            </div>
            <div className="col-xl-6 order-xl-first">
              <div className="bg-dark text-center h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 my-auto text-center text-xl-right">
                    <h4 className="text-white">Training (Workshops)</h4>
                    <p className="mb-0 text-white-50">
                      I volunter at MusesCodeJS running some WorkShops
                      (TypeScript) and mentor JavaScript workshops such as
                      React, MongoDB and NodeJS
                    </p>
                    <hr className="d-none d-xl-block mb-0 mr-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h2 className="mt-5 mb-3">Talks</h2>

          {talks.map(talk => (
            <div
              key={talk.id}
              className="resume-item d-flex flex-column flex-md-row justify-content-between mb-3"
            >
              <div className="resume-content">
                <h3 className="mb-0">
                  <Link to={`talk/${talk.Slug}`}>{talk.Title}</Link>
                </h3>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default SpeakingPage

export const pageQuery = graphql`
  query SpeakingQuery {
    strapiPages(Slug: { eq: "speaking" }) {
      Title
      Slug
    }
    allStrapiTalks(sort: { fields: id, order: DESC }) {
      nodes {
        id
        Slug
        Title
      }
    }
  }
`
